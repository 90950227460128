





















import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
    props:{
        info:{
            type: Array as PropType<{key: string, value: string, minor?: boolean}[]>,
            required: true
        },
        width: {
            type: Number,
            required: false
        }
    },
    setup(props) {
        const widthStyle = computed(() => props.width && {width: `${props.width}rem`})

        return {
            widthStyle
        }
    }
})
