


















































































































































import { computed, defineComponent, reactive, toRefs, onActivated, PropType } from '@vue/composition-api'
import { products } from '@/store/products'
import { Product, ProductEssential } from '@/types/interfaces'
import { getProduct, getProductReplacement } from '@/core/repository/product-repository'
import { extractProductQualities, extractNutritionalInformation } from '@/core/service/product-service'

export default defineComponent({
  name: 'FindReplacment',
  props:{
    preSelection: {
      type: Object as PropType<ProductEssential>,
      required: false,
    }
  },
 setup(props) {
  onActivated(() => {
    if(props.preSelection) {
      handleSelect(props.preSelection)
    }
  })
  const state = reactive({
    productSelection: null as null | ProductEssential,
    product: null as null | Product,
    replacementProduct: null as null | Product,
    isLoading: false
  })

  const qualitiesInfo = computed(() => {
    if(state.product && state.replacementProduct) {
      return extractProductQualities([state.product, state.replacementProduct] as Product[])
    }
  })
  const nutritionalInfo = computed(() => {
    if(state.product && state.replacementProduct) {
      return extractNutritionalInformation([state.product, state.replacementProduct] as Product[])
    }
  })

  

  const couldNotFindReplacement = computed(() => !state.isLoading && state.product && !state.replacementProduct)
  const hasReplacment = computed(() => state.product && state.replacementProduct)


  async function handleSelect(product: ProductEssential) {
    state.isLoading = true
    state.productSelection = product
    state.product = await getProduct(product.id)
    state.replacementProduct = await getProductReplacement(product.id)
    state.isLoading = false

  }
  return {
    ...toRefs(state),
    qualitiesInfo,
    nutritionalInfo,
    couldNotFindReplacement,
    hasReplacment,
    products,
    handleSelect,
  }
 }
})
