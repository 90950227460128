






import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    slim: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const slimClass = computed(() => props.slim && 'main-container-shell--slim')

    return {
      slimClass
    }
  }
})
