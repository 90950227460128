import { ProductResponse } from '@/core/repository/product-repository'
import { reactive, computed } from '@vue/composition-api'


const state = reactive({
    products: null as null | ProductResponse[]
})

export function storeProducts(products: ProductResponse[]) {
    state.products = products
}

export const products = computed(() => state.products)