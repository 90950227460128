import Vue from 'vue'

import '@/plugins/vue-composition-api'
import '@/plugins/vue-material-design-icons'
import '@/plugins/v-click-outside'
import '@/plugins/vue-tabs-component'
import '@/plugins/vue-multiselect'
import '@/assets/scss/main.scss'

import App from './App.vue'
import router from './router'

import { initialize } from '@/core/startup'

Vue.config.productionTip = false

initialize()

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
