import { render, staticRenderFns } from "./ProductFilters.vue?vue&type=template&id=f763c986&scoped=true&"
import script from "./ProductFilters.vue?vue&type=script&lang=ts&"
export * from "./ProductFilters.vue?vue&type=script&lang=ts&"
import style0 from "./ProductFilters.vue?vue&type=style&index=0&id=f763c986&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f763c986",
  null
  
)

export default component.exports