












































import { getProduct } from '@/core/repository/product-repository'
import { extractProductQualities, extractNutritionalInformation } from '@/core/service/product-service'
import { Product } from '@/types/interfaces'
import { defineComponent, onMounted, reactive, toRefs, computed, watch } from '@vue/composition-api'

export default defineComponent({
  props:{
    id:{
      type: String,
      required: true
    }
  },
  setup(props){
    const state = reactive({
      product: null as null | Product
    })

    onMounted( async () => {
      state.product = await getProduct(props.id)
    })

    const productQualitiesInfo = computed(() => extractProductQualities(state.product))

    const productNutritionalInfo = computed(() => extractNutritionalInformation(state.product))

    watch(()=> props.id, async () => {
      state.product = await getProduct(props.id)
      
    })

    return {
      ...toRefs(state),
      productQualitiesInfo,
      productNutritionalInfo
    }
  }
})
