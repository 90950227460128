










import { computed, defineComponent } from '@vue/composition-api'
import _ from 'lodash'

export default defineComponent({
  setup(props, context){
    const proxyValue = computed({
      get() {return context.attrs.value},
      set(value: unknown){ context.emit('input', value)}
    })
    const closeOnSelect = computed(() => !context.attrs.multiple)
    const mergedProps = computed(() => {
      const defaultProps = {
        multiple: false,
        showPointer: false,
        selectedLabel: '',
        deselectedLabel: '',
        closeOnSelect: closeOnSelect.value,
        placeholder: 'Välj alternativ'
      }

      

      return _.merge(defaultProps, context.attrs)
    })

    return {
      proxyValue,
      mergedProps
    }
  }
})
