import { apiRequest } from '@/core/service/http-service'
import { ChartOption, Product } from '@/types/interfaces'
import _ from 'lodash'

interface ProductStatic {
  name: string, 
  id: string,
}

interface ProductDynamic {
  [key: string]: string | number
}

export interface ProductResponse extends ProductStatic, ProductDynamic {}

export async function getProduct(id: string) {
  const response = await apiRequest<Product>('GET', `product/${id}`)
  return response
}

export async function getProducts(value?: string, filters = {}): Promise<ProductResponse[]> {
    const params = _.merge({field: ['name', 'id', value]}, filters)
    const response =  await apiRequest<ProductResponse[]>('GET', 'products', {params: params})
          
    
    return response
}

export async function getChartOptions() {
  const response =  await apiRequest<ChartOption[]>('GET', 'charts')
  return response
}

export async function getProductReplacement(id: string) {
  const response = await apiRequest<Product>('GET', `product/${id}/replacement`)
  return response
}