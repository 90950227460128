






import { defineComponent, PropType, watch, computed, ComputedRef } from '@vue/composition-api'
import { ChartType, ChartConfiguration } from 'chart.js/auto'
import chartConfig from '@/core/chart-config'
import { Product } from '@/types/interfaces'
import useReactiveCharts from '@/composables/useReactiveChart'

export default defineComponent({
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },
  setup(props) {
    
    const chartData = computed(() => {
      return {
        type: 'doughnut' as ChartType,
        data: {
          labels: ['Fett', 'Kolhydrater', 'Protein', 'Fibrer'],
          datasets: [
            {
              data: [
                getEnergyPercent(props.product.fat, 9),
                getEnergyPercent(props.product.carbohydrates, 4),
                getEnergyPercent(props.product.protein, 4),
                getEnergyPercent(props.product.fiber, 2),
              ],
              backgroundColor: chartConfig.backgroundColor,
              borderColor: chartConfig.borderColor,
              borderWidth: chartConfig.borderWidth,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'bottom',
            },
            tooltip: {
              callbacks: {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                label: function (context: any) {
                  return `${context.label}: ${context.formattedValue}E%`
                },
              },
            },
          },
        },
      }
    })
    
    const { renderChart } = useReactiveCharts(chartData as ComputedRef<ChartConfiguration>, `canvas__${props.product.id}`)

    watch(() => props.product,() => renderChart(),{ deep: true })

    function getEnergyPercent(grams: number, kcalPerGram: number) {
      return Math.round(((grams * kcalPerGram) / props.product.energy) * 100)
    }
  },
})
