import {computed, reactive, toRef, watch} from '@vue/composition-api'
import { ChartOption } from '@/types/interfaces'
import { getChartOptions, getProducts, ProductResponse } from '@/core/repository/product-repository'
import _ from 'lodash'

const state = reactive({
    productData: null as null | ProductResponse[],
    selectedChart: {display_name: 'Energy (kcal/100g)', field_name: 'energy'},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedFilters: {} as any,
    chartOptions: null as null | ChartOption[]
})

async function loadChartOptions(): Promise<void> {
    const options = await getChartOptions()
    state.chartOptions = options
}

async function loadProductData(): Promise<void> {
    const filters = extractFilters()
    state.productData = await getProducts(state.selectedChart.field_name, filters)
}

function extractFilters() {
    return _.mapValues(state.selectedFilters, filter => filter.map((item: { display_name: string, value: string }) => {
        return item.value
    }))
}

watch([() => state.selectedFilters, () => state.selectedChart], async () => {
    await loadProductData()
}, {deep: true})


const selectedFilters = computed(() => state.selectedFilters)
const productData = computed(() => state.productData)
const chartOptions = computed(() => _.orderBy(state.chartOptions, ['display_name'], ['asc']))

const selectedChart = toRef(state, 'selectedChart')

export {
    loadChartOptions,
    loadProductData,
    selectedFilters,
    selectedChart,
    productData,
    chartOptions
}



