
























import { computed, defineComponent, PropType } from '@vue/composition-api'

type NavCardTheme = 'primary' | 'secondary' | 'tertiary'

interface NavCardConfig {
  theme?: NavCardTheme,
  title: string,
  text: string,
  to?: string,
  right: boolean,
  imgSrc: string,
}


export default defineComponent({
    props: {
        config: {
          type: Object as PropType<NavCardConfig>,
          required: true
        }
    },
    setup(props) {
      const themeClass = computed(() => props.config.theme ? `nav-card--${props.config.theme}` : 'nav-card--primary')
      const imageRightClass = computed(() => props.config.right && 'nav-card__image-container--right')
      const componentIs = computed(() => props.config.to ? 'router-link' : 'div')


      return {
        themeClass,
        imageRightClass,
        componentIs
      }
    }
})
