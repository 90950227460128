

















import { SectionTable } from '@/types/interfaces'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
    props: {
        sections: {
            type: Array as PropType<SectionTable[]>,
            required: true
        },
        width: {
            type: Number,
            default: 15
        }
    }
})
