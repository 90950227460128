import { ComputedRef, onMounted, onUnmounted } from '@vue/composition-api'
import Chart, { ChartConfiguration, ChartItem } from 'chart.js/auto'
import _ from 'lodash'

export default function useDynamicChart(chartData: ComputedRef<ChartConfiguration>, canvasId: string) {
    let chart: Chart

  function renderChart() {
      if (chart) {
        chart.destroy()
      }
      const ctx = document.getElementById(canvasId) as ChartItem
      chart = new Chart(ctx, chartData.value)

  }
  const debounceRenderChart = _.debounce(renderChart, 500)
    
  onMounted(() => {
      renderChart()
      window.addEventListener('resize', debounceRenderChart)
    })
  
  onUnmounted(() =>
    window.removeEventListener('resize', debounceRenderChart)
  )

  return {
    renderChart
  }

}