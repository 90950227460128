import { Product } from '@/types/interfaces'
import _ from 'lodash'
import { extractPairArray, translateBoolean } from '../util'

function isSingleProducts(product: Product | Product[]): product is Product {
  return !_.isArray(product)
}

// eslint-disable-next-line @typescript-eslint/ban-types
function getValue(product: Product | Product[], key: keyof Product, helper?: Function) {
  if(isSingleProducts(product)) {
    return helper ? helper(product[key]) : product[key] 
  }
  else {
    return _.map(product, item => helper ? helper(item[key]) : item[key]) 
  }
}

function hasDifference(value: string | string[]) {
  if(_.isArray(value)) {
    return !_.every(value, item => item === value[0])
  }
  return false
}

export function extractProductQualities(product: Product | Product[] | null) {
    if(product) {
      return [
          {
            key: 'Typ av produkt',
            value: getValue(product, 'product_types', extractPairArray),
            differs: hasDifference(getValue(product, 'product_types', extractPairArray))
          },
          {
            key: 'Primär målgrupp',
            value: getValue(product, 'age_groups', extractPairArray),
            differs: hasDifference(getValue(product, 'age_groups', extractPairArray))
          },
          {
            key: 'Sjukdomsspecifik',
            value: getValue(product, 'diagnosis', extractPairArray),
            differs: hasDifference(getValue(product, 'diagnosis', extractPairArray))
          },
          {
            key: 'Smaker',
            value: getValue(product, 'tastes', extractPairArray),
            differs: hasDifference(getValue(product, 'tastes', extractPairArray))
          },
          {
            key: 'Hydrolyserad',
            value: getValue(product, 'hydrolysis'),
            differs: hasDifference(getValue(product, 'hydrolysis'))
          },
          {
            key: 'Allergianpassad',
            value: getValue(product, 'allergies', extractPairArray),
            differs: hasDifference(getValue(product, 'allergies', extractPairArray))
          },
          {
            key: 'Glutenfri',
            value: getValue(product, 'gluten_free', translateBoolean),
            differs: hasDifference(getValue(product, 'gluten_free', translateBoolean))
          },
          {
            key: 'Laktoshalt',
            value: getValue(product, 'lactose_content'),
            differs: hasDifference(getValue(product, 'lactose_content'))
          },
          {
            key: 'Konsistens',
            value: getValue(product, 'textures', extractPairArray),
            differs: hasDifference(getValue(product, 'textures', extractPairArray))
          },
          {
            key: 'Komplett',
            value: getValue(product, 'complete', translateBoolean),
            differs: hasDifference(getValue(product, 'complete', translateBoolean))
          },
          {
            key: 'Pulverbaserad',
            value: getValue(product, 'powder_based', translateBoolean),
            differs: hasDifference(getValue(product, 'powder_based', translateBoolean))

          },
          {
            key: 'Klar/Fettfri',
            value: getValue(product, 'transparent', translateBoolean),
            differs: hasDifference(getValue(product, 'transparent', translateBoolean))

          },
          {
            key: 'Proteinberikad',
            value: getValue(product, 'protein_enriched', translateBoolean),
            differs: hasDifference(getValue(product, 'protein_enriched', translateBoolean))

          },
          {
            key: 'Energiberikad',
            value: getValue(product, 'energy_enriched', translateBoolean),
            differs: hasDifference(getValue(product, 'energy_enriched', translateBoolean))

          },
          {
            key: 'Fiberberikad',
            value: getValue(product, 'fiber_enriched', translateBoolean),
            differs: hasDifference(getValue(product, 'fiber_enriched', translateBoolean))

          },
          {
            key: 'Compact',
            value: getValue(product, 'compact', translateBoolean),
            differs: hasDifference(getValue(product, 'compact', translateBoolean))

          },
          {
            key: 'Energireducerad',
            value: getValue(product, 'energy_reduced', translateBoolean),
            differs: hasDifference(getValue(product, 'energy_reduced', translateBoolean))

          },
          {
            key: 'MCT-baserad',
            value: getValue(product, 'mct_based', translateBoolean),
            differs: hasDifference(getValue(product, 'mct_based', translateBoolean))

          },
          {
            key: 'L-märkt',
            value: getValue(product, 'l_marked', translateBoolean),
            differs: hasDifference(getValue(product, 'l_marked', translateBoolean))

          },
          {
            key: 'Halal-certifierad',
            value: getValue(product, 'halal_certified', translateBoolean),
            differs: hasDifference(getValue(product, 'halal_certified', translateBoolean))

          },
          {
            key: 'Extra näringstät',
            value: getValue(product, 'extra_nutrition_dense', translateBoolean),
            differs: hasDifference(getValue(product, 'extra_nutrition_dense', translateBoolean))

          },
        ]

    }
}

export function extractNutritionalInformation(product: Product | Product[] | null) {
    if(product) {
      return [
        {
          name: 'Makronutrienter',
          values: [
            {
              key: 'Energi (kcal)',
              value: getValue(product, 'energy')
            },
            {
              key: 'Fett (g)',
              value: getValue(product, 'fat')
            },
            {
              key: '- varav mättat fett (g)',
              value: getValue(product, 'saturated_fat'),
              minor: true
            },
            {
              key: 'Kolhydrater (g)',
              value: getValue(product, 'carbohydrates')
            },
            {
              key: '- varav socker (g)',
              value: getValue(product, 'sugar'),
              minor: true
            },
            {
              key: '- varav laktos (g)',
              value: getValue(product, 'lactose'),
              minor: true
            },
            {
              key: 'Protein (g)',
              value: getValue(product, 'protein'),
            },
            {
              key: 'Fibrer (g)',
              value: getValue(product, 'fiber'),
            },
            {
              key: 'Vatten (g)',
              value: getValue(product, 'water'),
            }
          ]
        },
        {
          name: 'Vitaminer',
          values: [
            {
              key: 'Vitamin A (ug RE)',
              value: getValue(product, 'vitamin_a')
            },
            {
              key: 'Vitamin D (ug)',
              value: getValue(product, 'vitamin_d')
            },
            {
              key: 'Vitamin E (mg)',
              value: getValue(product, 'vitamin_e')
            },
            {
              key: 'Vitamin K (ug)',
              value: getValue(product, 'vitamin_k')
            },
            {
              key: 'Vitamin C (mg)',
              value: getValue(product, 'vitamin_c')
            },
            {
              key: 'Tiamin (mg)',
              value: getValue(product, 'thiamine')
            },
            {
              key: 'Riboflavin (mg)',
              value: getValue(product, 'riboflavin')
            },
            {
              key: 'Niacin (mg NE)',
              value: getValue(product, 'niacin')
            },
            {
              key: 'Vitamin B6 (mg)',
              value: getValue(product, 'vitamin_b6')
            },
            {
              key: 'Folsyra (ug)',
              value: getValue(product, 'folic_acid')
            },
            {
              key: 'Vitamin B12 (ug)',
              value: getValue(product, 'vitamin_b12')
            },
            {
              key: 'Biotin (ug)',
              value: getValue(product, 'biotin')
            },
            {
              key: 'Pantotensyra (mg)',
              value: getValue(product, 'pantothenic_acid')
            },
          ]
        },
        {
          name: 'Mineraler',
          values: [
            {
              key: 'Natrium (mg)',
              value: getValue(product, 'sodium')
            },
            {
              key: 'Kalium (mg)',
              value: getValue(product, 'potassium')
            },
            {
              key: 'Klorid (mg)',
              value: getValue(product, 'chloride')
            },
            {
              key: 'Kalcium (mg)',
              value: getValue(product, 'calcium')
            },
            {
              key: 'Fosfor (mg)',
              value: getValue(product, 'phosphorus')
            },
            {
              key: 'Magnesium (mg)',
              value: getValue(product, 'magnesium')
            },
            {
              key: 'Järn (mg)',
              value: getValue(product, 'iron')
            },
            {
              key: 'Zink (mg)',
              value: getValue(product, 'zinc')
            },
            {
              key: 'Koppar (mg)',
              value: getValue(product, 'copper')
            },
            {
              key: 'Mangan (mg)',
              value: getValue(product, 'manganese')
            },
            {
              key: 'Fluorid (mg)',
              value: getValue(product, 'fluoride')
            },
            {
              key: 'Selen (ug)',
              value: getValue(product, 'selenium')
            },
            {
              key: 'Krom (ug)',
              value: getValue(product, 'chromium')
            },
            {
              key: 'Molybden (ug)',
              value: getValue(product, 'molybdenum')
            },
            {
              key: 'Jod (ug)',
              value: getValue(product, 'iodine')
            },
          ]
        }
      ]
    }
}