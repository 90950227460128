import axios, { AxiosRequestConfig, Method } from 'axios'
import env from '@/core/env'
import _ from 'lodash'
import qs from 'query-string'

async function apiRequest<T>(method: Method, endpoint: string, config: AxiosRequestConfig = {}) : Promise<T>{
    const defaultConfig: AxiosRequestConfig = {
        method,
        url: `${env.baseUrl}/${endpoint}`,
        headers: {'Content-Type': 'application/json', Accept: 'application/json'},
        paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: 'none'})
          }
    }

    const requestConfig = _.merge(defaultConfig, config)

    const { data } = await axios(requestConfig)

    return data as T
}

export { apiRequest }