export default {
    backgroundColor: [
        'rgba(255, 99, 132, 0.2)', // red
        'rgba(255, 205, 86, 0.2)', // yelloq
        'rgba(54, 162, 235, 0.2)', // blue
        'rgba(75, 192, 192, 0.2)', // green
        'rgba(153, 102, 255, 0.2)', // purple
        'rgba(201, 203, 207, 0.2)', // grey
        'rgba(255, 159, 64, 0.2)', // orange
      ],
      borderColor: [
        'rgb(255, 99, 132)', //red
        'rgb(255, 205, 86)', // yellow
        'rgb(54, 162, 235)', // blue
        'rgb(75, 192, 192)', // green
        'rgb(153, 102, 255)', // purple
        'rgb(201, 203, 207)', //grey
        'rgb(255, 159, 64)', //orange
      ],
      borderWidth: 1
}