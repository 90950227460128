import Vue from 'vue'
import _ from 'lodash'
import { getProducts } from '@/core/repository/product-repository'
import { storeProducts } from '@/store/products'

const pascalCase = (name: string) =>
  _.chain(name).camelCase().upperFirst().value()

function registerComponents() {
  const context = require.context('@/components', true, /[A-Z]\w+\.(vue|js)$/)

  context.keys().forEach((fileName) => {
    const componentConfig = context(fileName)
    const name = (fileName.split('/').pop() || '').replace(/\.\w+$/, '')
    const componentName = pascalCase(name)

    Vue.component(componentName, componentConfig.default || componentConfig)
  })
}

async function loadProducts() {
  const products = await getProducts()
  storeProducts(products)
}

function initialize(): void {
  registerComponents()
  loadProducts()
}

export { initialize }
