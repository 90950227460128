



























import { defineComponent, onMounted } from '@vue/composition-api'
import {productData, loadProductData, selectedFilters, loadChartOptions, chartOptions, selectedChart} from '@/store/find-product'
import BaseCard from '@/components/base/BaseCard.vue'


export default defineComponent({
  components: { BaseCard },
  setup() {
    onMounted(async () => {
      await loadChartOptions()
      await loadProductData()
    })

    return {
      productData,
      selectedFilters,
      chartOptions,
      selectedChart
    }
  }
})
