import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Calculate from '../views/Calculate.vue'
import FindProduct from '../views/FindProduct.vue'
import FindReplacment from '../views/FindReplacment.vue'
import ProductDetail from '../views/ProductDetail.vue'
import TermsOfUse from '../views/TermsOfUse.vue'
import NotFound from '../views/NotFound.vue'
import Development from '../views/Development.vue'
import env from '@/core/env'



Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/calculate',
    name: 'Calculate',
    component: Calculate
  },
  {
    path: '/find-product',
    name: 'FindProduct',
    component: FindProduct
  },
  {
    path: '/find-replacment',
    name: 'FindReplacment',
    props: true,
    component: FindReplacment
  },
  {
    path: '/product/:id',
    name: 'ProductDetail',
    props: true,
    component: ProductDetail
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: TermsOfUse
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
]

if(env.isDevelopment) {
  routes.push({
    path: '/testpage',
    name: 'Development',
    props: true,
    component: Development
  },)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
