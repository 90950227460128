










































































import router from '@/router'
import { defineComponent, ref } from '@vue/composition-api'
import { products } from '@/store/products'


export default defineComponent({
  setup() {
    const showMenu = ref(false)

    const toggleMenu = () => {
      console.log('toggle menu')
      console.log(showMenu.value)
      
      showMenu.value = !showMenu.value
      console.log(showMenu.value)
    }
    const closeMenu = () => {
      console.log('closeMenu')
      
      showMenu.value = false
      }

    function handleSelect(item:{ id: string, name: string }) {
      console.log('handleSelect')
      
      showMenu.value = false
      router.push({name: 'ProductDetail', params: {id: item.id}})
    }


    return {
      showMenu,
      products,
      toggleMenu,
      closeMenu,
      handleSelect
    }
  }
})
