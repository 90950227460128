






import { defineComponent, PropType, watch, computed, ComputedRef} from '@vue/composition-api'
import { ChartType, ChartConfiguration } from 'chart.js/auto'
import chartConfig from '@/core/chart-config'
import { selectedChart } from '@/store/find-product'
import { ProductResponse } from '@/core/repository/product-repository'
import router from '@/router'
import useReactiveCharts from '@/composables/useReactiveChart'


export default defineComponent({
    props: {
      products: {
        type: Array as PropType<ProductResponse[]>,
        required: true
      }
    },
    setup(props) {
      const labels = computed(() => props.products.map(item => item.name))
      const data = computed(() => props.products.map(item => item[selectedChart.value.field_name]))

      const chartData = computed(() => {
        return {
        type: 'bar' as ChartType,
        data: {
          labels: labels.value,
          datasets: [{
            data: data.value,
            backgroundColor: chartConfig.backgroundColor,
            borderColor: chartConfig.borderColor,
            borderWidth: chartConfig.borderWidth
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: 'y',
          plugins: {
            legend: {
              display: false
            }
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick: function(event:any, elements:any) {
            const element = elements[0]
            if(element) {
              router.push({path: `product/${props.products[element.index].id}`})
            }
          }
        }
      }
      })

      const { renderChart } = useReactiveCharts(chartData as ComputedRef<ChartConfiguration>, 'canvas')

      watch(() => props.products, () => {
        renderChart()
      }, {deep: true})
    }
})
