import { Pair } from '@/types/interfaces'
import _ from 'lodash'

function extractPairArray(array : Pair[] = []) {
    return _.chain(array).map(item => item.name).join(', ').value()
}

function translateBoolean(bool: boolean | undefined | null) {
    if(_.isBoolean(bool)) {
        return bool ? 'Ja' : 'Nej'
    }
    else  {
        return 'Värde saknas'
    }
}

export {
    extractPairArray,
    translateBoolean
}