























import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { apiRequest } from '@/core/service/http-service'
import {selectedFilters} from '@/store/find-product'

type Filter = {
  'filter_name': string,
  'filter_options': string,
  'query_param': string
}

export default defineComponent({
    setup() {
      const filters = ref<null | Filter[]>(null)

      onMounted(async () => {
        const response =  await apiRequest<Filter[]>('GET', 'filters', {})
        filters.value = response
      })

      return {
        filters,
        selectedFilters
      }
    }
})
