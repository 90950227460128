




































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup() {
    const navCardInfo = [
      {
        title: 'Hitta produkt',
        text: 'Här kan man enkelt filtrera fram produkter baserat på vilka produktegenskaper man önskar och sedan jämföra alternativen.',
        to: '/find-product',
        theme: 'secondary',
        imgSrc: require('@/assets/images/findproduct.png')
      },
      {
        title: 'Hitta ersättningsprodukt',
        text: 'Du kanske har hittat en lämplig produkt men av någon anledning finns den inte tillgänglig? Inga problem. Nutribaan ge dig förslag på en liknande produkt.',
        to: '/find-replacment',
        theme: 'tertiary',
        right: true,
        imgSrc: require('@/assets/images/replacement.png')
      },
      {
        title: 'Standardiserade produktfaktablad',
        text: 'Oavsett produkt eller tillverkare så presenterar Nutribaan alla produkter på ett standadriserat sätt och gör det enklare och snabbare att överblicka informationen.',
        theme: 'primary',
        imgSrc: require('@/assets/images/productdetails.png'),
      }
    ]

    return {
      navCardInfo
    }
  }
  
})
