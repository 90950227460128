import Vue from 'vue'

import MenuIcon from 'vue-material-design-icons/Menu.vue'
import MagnifyIcon from 'vue-material-design-icons/Magnify.vue'
import SwapHorizontalIcon from 'vue-material-design-icons/SwapHorizontal.vue'
import CalculatorIcon from 'vue-material-design-icons/Calculator.vue'
import FormatAlignLeftIcon from 'vue-material-design-icons/FormatAlignLeft.vue'





Vue.component('menu-icon', MenuIcon)
Vue.component('magnify-icon', MagnifyIcon)
Vue.component('swap-horizontal-icon', SwapHorizontalIcon)
Vue.component('calculator-icon', CalculatorIcon)
Vue.component('format-align-left-icon', FormatAlignLeftIcon)



